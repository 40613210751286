import { Flex, Text, Box, Button } from '@chakra-ui/react';
import Emoji from 'react-emojis';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const DevNotice = () =>
<Flex
  py={4}
  px={2}
  bg='accent-alt'
  fontSize='xl'
>
<Flex
  width='full'
  maxWidth='container.lg'
  mx='auto'
>
<Box
  mx='auto'
  alignItems='center'
>

  <Flex>
    <Emoji emoji="construction" lineHeight="inherit" size="32"/>
    <Text
      mx={2}
    >
      Under development. There will be dragons.
    </Text>
    <Emoji emoji="construction" lineHeight="inherit" size="32" />
  </Flex>

  {/* <Button */}
  {/*   mx='auto' */}
  {/*   mt={2} */}
  {/*   size='md' */}
  {/*   boxShadow='md' */}
  {/*   rightIcon={<ArrowForwardIcon />} */}
  {/*   as='a' */}
  {/*   href='mailto:ai@trestripes.com' */}
  {/* > */}
  {/*   Email Us */}
  {/* </Button> */}

</Box>
</Flex>
</Flex>

export default DevNotice;
