import { Flex, Text, Box } from '@chakra-ui/react';

const Nav = () =>
<Flex
  px={4}
  py={5}
  bg='white'
  fontSize='3xl'
>
  <Flex
    px={6}
    py={2}
    width='full'
    maxWidth='container.lg'

    mx='auto'
    alignItems='center'

    bg='white'
    color='black'
    borderRadius='full'
    borderWidth={1}
    borderColor='black'
  >

    <Text
      fontWeight='bold'
      fontFamily='heading'
    >
      ||| TRESTRIPES
    </Text>

    <Box mx='auto' />

    {/* <Button */}
    {/*   mr={2} */}
    {/*   bg='gray.100' */}
    {/*   color='black' */}
    {/*   borderRadius={5} */}
    {/* > */}
    {/*   Log in */}
    {/* </Button> */}
    {/* <Button */}
    {/*   colorScheme='red' */}
    {/*   bg='negative.2' */}
    {/*   color='white' */}
    {/*   borderRadius='full' */}
    {/* > */}
    {/*   Sign up */}
    {/* </Button> */}

  </Flex>
</Flex>

export default Nav;
