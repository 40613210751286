const theme = {
  fonts: {
    body: '"Rubik", system-ui, sans-serif',
    heading: '"Rubik", system-ui, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 200,
    heading: 800,
  },
  colors: {
    white: '#FFF',
    black: '#000',

    primary: '#00a8e8',
    secondary: '#020887',

    'primary-50': '#ebf9ff',
    'primary-100': '#d6f3ff',
    'primary-200': '#c2edff',

    accent: '#fcf300',
    'accent-alt': '#ffd200',
  },
}

export default theme
