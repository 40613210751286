import { Box, Text, Heading } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';

import img1 from './images/1.png';
import img2 from './images/2.png';
import img3 from './images/3.png';
import img4 from './images/4.png';
import img5 from './images/5.png';
import img6 from './images/6.png';
import img7 from './images/7.png';
import img8 from './images/8.png';
import img9 from './images/9.png';
import img10 from './images/10.png';
import img11 from './images/11.png';
import img12 from './images/12.png';

const Main = () =>
<Box
  px={4}
  bg='white'
  fontSize='2xl'
>
  <Box
    px={6}
    pt={6}
    pb={12}
    width='full'
    maxWidth='container.lg'
    mx='auto'
  >

    <Box
      my={16}
      maxWidth='container.md'
      textAlign='left'
    >
      <Heading
      >
        AI can create anything
      </Heading>
      <Text
        fontSize='xl'
      >
        Leverage AI to boost your creativity.
        Instantly generate anything you need,
        exactly to your specifications.
        Create infinite variations, use the best ones.
      </Text>
    </Box>

  </Box>

  <Box
  >
    <Marquee
    >
      <Box height='512px' as='img' src={img1} />
      <Box height='512px' as='img' src={img2} />
      <Box height='512px' as='img' src={img3} />
      <Box height='512px' as='img' src={img4} />
      <Box height='512px' as='img' src={img5} />
      <Box height='512px' as='img' src={img6} />
      <Box height='512px' as='img' src={img7} />
      <Box height='512px' as='img' src={img8} />
      <Box height='512px' as='img' src={img9} />
      <Box height='512px' as='img' src={img10} />
      <Box height='512px' as='img' src={img11} />
      <Box height='512px' as='img' src={img12} />
    </Marquee>
  </Box>

</Box>

export default Main;
