import { useState } from 'react';
import { Box, Heading, Text, Button, Textarea } from '@chakra-ui/react';
import { EmailIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import bgImage from './images/pattern/37.jpg'

const url = 'https://api.trestripes.com/website/contact';

const Contact = () => {
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleMessageChange = (e) => {
    const value = e.target.value
    setMessage(value)
  }
  const sendMessage = async (e) => {
    if (message === '') {
      return null
    }
    try {
      const _response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message
        }),
      })
      setMessage('')
      setError('')
    } catch (error) {
      console.error(`sendMessage : ${error.message}`)
      setError('Error sending message, try again please.')
    }
  }

  return (
    <Box
      px={4}
      py={32}
      fontSize='2xl'
      backgroundColor='white'
    >
    <Box
      px={8}
      py={14}
      mx='auto'
      maxWidth='md'
      backgroundImage={bgImage}
      backgroundSize='cover'
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      boxShadow='dark-lg'
    >

      <Heading
        mx='auto'
        mt={5}
        color='white'
      >
        How will you use AI?
      </Heading>

      <Button
        mx='auto'
        my={5}
        size='lg'
        boxShadow='dark-lg'
        leftIcon={<EmailIcon />}
        as='a'
        href='mailto:ai@trestripes.com'
      >
        Email Us
      </Button>

      <Textarea
        value={message}
        onChange={handleMessageChange}
        placeholder='Or send us a message!'
        mt={20}
        minHeight={140}
        bg='white'
        isRequired={true}
        isInvalid={error !== ''}
        size='lg'
        resize='none'
        boxShadow='dark-lg'
        borderWidth={2}
      />

      <Button
        mx='auto'
        my={5}
        size='lg'
        boxShadow='dark-lg'
        rightIcon={<ArrowForwardIcon />}
        onClick={sendMessage}
      >
        Send Message
      </Button>

    </Box>

    <Text
      my={5}
      fontSize={20}
      color='black'
    >
      {error !== '' ? error : null}
    </Text>

    </Box>
  )
}

export default Contact;
