import './App.css';

import { Box } from '@chakra-ui/react';

import DevNotice from './DevNotice.js';
import Nav from './Nav.js';
import Main from './Main.js';
import Contact from './Contact.js';

const App = () =>
<Box
  className="App"
  bg='black'
>

  <DevNotice />

  <Nav />

  <Main />

  <Contact />

</Box>

export default App;
